(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-time-limits/assets/time-limits.js') >= 0) return;  svs.modules.push('/components/payment/middleware-time-limits/assets/time-limits.js');
'use strict';

const {
  InteractionContext
} = svs.components.payment.common.context;
const {
  MiddlewarePaymentError
} = svs.components.payment.common.error;
const {
  getReturnUrl,
  logger
} = svs.components.payment.common;
const {
  urlMapping
} = svs.core;
const timeLimits = onInteraction => () => async next => {
  const timeLimitData = await svs.accountServices.player_timelimits.getPlayerTimeLimits();
  logger.info("Checking time limits...");
  const title = 'Du har nått din tidsgräns';
  const messages = ['När du har nått din tidsgräns kan du inte betala för spel på Sport och Casino.'];
  if ((timeLimitData === null || timeLimitData === void 0 ? void 0 : timeLimitData.day.remaining) === '0' || (timeLimitData === null || timeLimitData === void 0 ? void 0 : timeLimitData.week.remaining) === '0' || (timeLimitData === null || timeLimitData === void 0 ? void 0 : timeLimitData.month.remaining) === '0') {
    try {
      var _returnUrl;
      const result = await onInteraction(new InteractionContext(InteractionContext.Severity.ERROR, InteractionContext.Code.TIME_LIMIT_EXCEEDED, timeLimitData, title, messages));
      let returnUrl = getReturnUrl(window.location, result);
      returnUrl = svs.utils.url.makeInternal((_returnUrl = returnUrl) === null || _returnUrl === void 0 ? void 0 : _returnUrl.href);
      svs.utils.url.navigate("".concat(urlMapping.get('myProfileLimits'), "?returnUrl=").concat(returnUrl));
    } catch (err) {
      logger.debug(err.message, err);
    }
    return next(new MiddlewarePaymentError({
      clientCode: MiddlewarePaymentError.ErrorCodes.TIME_LIMIT
    }));
  }
  next();
};
setGlobal('svs.components.payment.middleware.timeLimits', timeLimits);

 })(window);